












































import { Component } from "vue-property-decorator";
import { validationMixin } from "vuelidate";
import SimpleForm from "@/components/SimpleForm.vue";
import SimpleButton from "../SimpleButton.vue";
import { passwordRecoveryForm } from "@/assets/scripts/form-fields/main";

const { values, attrs, validations, errors } = passwordRecoveryForm;

@Component({
  components: {
    SimpleForm,
    SimpleButton,
  },
  mixins: [validationMixin],
  computed: errors,
  validations,
})
class PasswordRecoveryForm extends SimpleForm {
  values = values;
  allFieldAttrs = attrs;

  useSubmitButtonLoading = false;

  private openSignInPage(): void {
    this.redirectToPage("signIn");
  }

  private async submitForm() {
    const values = this.getValues();
    console.log(values);
  }
}

export default PasswordRecoveryForm;
